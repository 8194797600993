<template>
  <div class="programme">
    <div class="banner" :style="{ backgroundImage: `url(${bannerUrl})` }">
      <div class="banner-box">
        <div class="banner-title">解决方案</div>
        <div class="title-eng">Total Solution</div>
        <div class="banner-msg">
          为您打造专属定制的全屋智能解决方案
        </div>
      </div>
    </div>
    <div class="main">
      <div class="content">
        <div class="hand">
          <div class="title-msg">提供行业专业的一站式全屋智能解决方案</div>
          <div class="p-msg"  id="solution">
            通过灵活的通讯方式及系统兼容性，灵鹿全屋智能产品品类涵盖10大智能板块，全面覆盖新装、改造、扩展等不同需求，
             确保不同空间有合适的解决方案；灵鹿全屋智能采用轻总线可DIY的系统架构，是全屋智能更稳定、更耐久、更可靠。
          </div>
        </div>
        <el-tabs class="tab1" v-model="activeName" stretch @tab-click="handleClick">
          <!-- 第一个 -->
          <el-tab-pane class="tab-pane" label="智能调光" name="first">
            <div class="box-one">
              <div class="ul">
                <div class="line1">
                  <img height="100%" width="100%" src="../assets/images/智能调光-01.jpg" alt="" />
                </div>
                <div class="line2">
                  <div class="t1">智能调光系统</div>
                  <div class="t2">
                    除了基础的亮度百分比调节、色温调节、变色调节外，我们还通过DALI、1-10、可控硅等调光技术让光变得更为细腻。不仅如此，色温和亮度还可根据一天的光影变换进行规律调节，让室内的灯光始终处于一个舒适的状态。
                  </div>
                  <div class="t-box">
                    <div class="t3">
                      <div class="t5">&bull;&nbsp;0.01%精度的极致调光</div>
                      <div class="t5">&bull;&nbsp;节律调光</div>
                    </div>
                    <div class="t4">
                      <div class="t5">&bull;&nbsp;RGB万色调光</div>
                      <div class="t5">&bull;&nbsp;双色温调光</div>
                    </div>
                  </div>
                  <div class="button-one">
                    <el-button round type="primary"  @click="modalShow = true">立即申请&nbsp;&gt;</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- 第二个 -->
          <el-tab-pane class="tab-pane" label="智能照明" name="second">
            <div class="box-one">
              <div class="ul">
                <div class="line1">
                  <img height="100%" width="100%" src="../assets/images/智能照明-02.jpg" alt="" />
                </div>
                <div class="line2">
                  <div class="t1">智能照明系统</div>
                  <div class="t2">
                    开灯，远不只一种选择，定时、声控、自动感应、App控制，随心触发，方便更节能。从此出门无需担心忘关灯，睡觉前无需再四处关灯，一键控制智能更节能。
                  </div>
                  <div class="t-box">
                    <div class="t3">
                      <div class="t5">
                        &bull;&nbsp;手机远程控制，不受距离限制
                      </div>
                      <div class="t5">
                        &bull;&nbsp;场景集中控制，化繁为简更便捷
                      </div>
                      <div class="t5">
                        &bull;&nbsp;联动智能控制，从此再也不操心
                      </div>
                    </div>
                  </div>
                  <div class="button-one">
                    <el-button round type="primary"  @click="modalShow = true">立即申请&nbsp;&gt;</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- 第三个 -->
          <el-tab-pane class="tab-pane" label="智能安防" name="third">
            <div class="box-one">
              <div class="ul">
                <div class="line1">
                  <img height="100%" width="100%" src="../assets/images/智能安防-03.jpg" alt="" />
                </div>
                <div class="line2">
                  <div class="t1">智能安防系统</div>
                  <div class="t2">
                    做现代生活的“千里眼”，在外行千里也要有看得见的守护才更放心。可配备高清摄像头、人体红外感应器、门磁报警器、烟雾传感器、煤气传感器，全方位全天候保护您的家，一旦有火灾或煤气泄露时，手机将自动弹屏并收到告警短信，严重时系统将自动报警，并启动安全模式进行及时阻断。不仅如此，手机还可实时连接家中的高清摄像头，随时查看家中状况，可一键呼叫家中的孩子、父母进行实时对讲。远在千里，爱在身边。
                  </div>
                  <div class="t-box">
                    <div class="t3">
                      <div class="t5">&bull;&nbsp;远程对讲</div>
                      <div class="t5">&bull;&nbsp;实时监测</div>
                    </div>
                    <div class="t4">
                      <div class="t5">&bull;&nbsp;预警告警</div>
                      <div class="t5">&bull;&nbsp;视频回放</div>
                    </div>
                  </div>
                  <div class="button-one">
                    <el-button round type="primary"  @click="modalShow = true">立即申请&nbsp;&gt;</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- 第四个 -->
          <el-tab-pane class="tab-pane" label="智能遮阳" name="fourth">
            <div class="box-one">
              <div class="ul">
                <div class="line1">
                  <img height="100%" width="100%" src="../assets/images/智能遮阳系统-04.jpg" alt="" />
                </div>
                <div class="line2">
                  <div class="t1">智能遮阳系统</div>
                  <div class="t2">
                    通过系统线路，可自动调整帘片角度或作整体升降开合，完成对遮阳窗帘、百叶帘的智能控制，可根据不同时间段的光照强度进行智能控制，既能及时减少阳光直射，避免产生眩光，又能充分利用自然光，节约能源。同时智能遮阳系统还可跟室内灯光进行亮度、色温的联动，让家更温暖、更舒适、更阳光。
                  </div>
                  <div class="t-box">
                    <div class="t3">
                      <div class="t5">&bull;&nbsp;角度、开合控制</div>
                      <div class="t5">&bull;&nbsp;联动智能控制</div>
                    </div>
                    <div class="t4">
                      <div class="t5">&bull;&nbsp;光照调节</div>
                    </div>
                  </div>
                  <div class="button-one">
                    <el-button round type="primary"  @click="modalShow = true">立即申请&nbsp;&gt;</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- 第五个 -->
          <el-tab-pane class="tab-pane" label="暖通控制" name="fifth">
            <div class="box-one">
              <div class="ul">
                <div class="line1">
                  <img height="100%" width="100%" src="../assets/images/暖通控制-05.jpg" alt="" />
                </div>
                <div class="line2">
                  <div class="t1">暖风空调系统</div>
                  <div class="t2">
                    冬知暖，夏知凉。暖风空调系统可自动调节室内温度，还可远程控制地暖、新风，让您的家庭多一份舒适，少一些感冒。并可与空气质量探测器监测到的CO₂、温度、湿度、VOC数值进行环境智能联动，进行及时通风、净化空气，从空气入手保护家人的健康。
                  </div>
                  <div class="t-box">
                    <div class="t3">
                      <div class="t5">&bull;&nbsp;远程控制</div>
                      <div class="t5">&bull;&nbsp;智能联动</div>
                    </div>
                    <div class="t4">
                      <div class="t5">&bull;&nbsp;深度调节控制</div>
                      <div class="t5">&bull;&nbsp;主流品牌全面兼容</div>
                    </div>
                  </div>
                  <div class="button-one">
                    <el-button round type="primary"  @click="modalShow = true">立即申请&nbsp;&gt;</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- 第六个               -->
          <el-tab-pane class="tab-pane" label="智能影音" name="sixth">
            <div class="box-one">
              <div class="ul">
                <div class="line1">
                  <img height="100%" width="100%" src="../assets/images/智能影音-06.jpg" alt="" />
                </div>
                <div class="line2">
                  <div class="t1">智能影音系统</div>
                  <div class="t2">
                    集影院、唱吧、聚会多种功能于一身，有了它家也可以成为娱乐中心。投影幕布、电动吊架、影音功能、高清碟机、背景音乐全自动控制，无需到处寻找各色遥控器就能一键开启场景模式。
                  </div>
                  <div class="t-box">
                    <div class="t3">
                      <div class="t5">&bull;&nbsp;一键控制</div>
                      <div class="t5">&bull;&nbsp;摆脱遥控器</div>
                    </div>
                    <div class="t4">
                      <div class="t5">&bull;&nbsp;化繁为简</div>
                      <div class="t5">&bull;&nbsp;深度对接</div>
                    </div>
                  </div>
                  <div class="button-one">
                    <el-button round type="primary"  @click="modalShow = true">立即申请&nbsp;&gt;</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- 第七个 -->
          <el-tab-pane class="tab-pane" label="能源管理" name="seventh">
            <div class="box-one">
              <div class="ul">
                <div class="line1">
                  <img height="100%" width="100%" src="../assets/images/能源管理-07.jpg" alt="" />
                </div>
                <div class="line2">
                  <div class="t1">能源管理系统</div>
                  <div class="t2">
                    通过24小时的全天候电能监测、云服务器的大数据分析，让每一处的用电数据都能一目了然。并随时为各类电器进行“体检”并开出家电监控诊断单，让其运行状态可视化。并可对每天的用电情况进行统一管理，根据天气情况和您的行为习惯，量身定制高效用电建议方案，让节能环保更轻松。
                  </div>
                  <div class="t-box">
                    <div class="t3">
                      <div class="t5">&bull;&nbsp;实时监测</div>
                      <div class="t5">&bull;&nbsp;数据分析输出</div>
                    </div>
                    <div class="t4">
                      <div class="t5">&bull;&nbsp;预警告警</div>
                      <div class="t5">&bull;&nbsp;智能更节能</div>
                    </div>
                  </div>
                  <div class="button-one">
                    <el-button round type="primary"  @click="modalShow = true">立即申请&nbsp;&gt;</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- 第八个 -->
          <el-tab-pane class="tab-pane" label="环境监测" name="eighth">
            <div class="box-one">
              <div class="ul">
                <div class="line1">
                  <img height="100%" width="100%" src="../assets/images/环境监测-08.jpg" alt="" />
                </div>
                <div class="line2">
                  <div class="t1">环境监测系统</div>
                  <div class="t2">
                    实时监测温度、湿度、光照度、噪声、TVOC、CO₂、PM2.5等环境数值，通过智能诊断可联动开启空调、加湿器、净化器、灯光等设备，打造一个温暖、舒适、健康的人居环境。
                  </div>
                  <div class="t-box">
                    <div class="t3">
                      <div class="t5">&bull;&nbsp;智能联动</div>
                      <div class="t5">&bull;&nbsp;数据可视化</div>
                    </div>
                    <div class="t4">
                      <div class="t5">&bull;&nbsp;全方位守护</div>
                    </div>
                  </div>
                  <div class="button-one">
                    <el-button round type="primary"  @click="modalShow = true">立即申请&nbsp;&gt;</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- 第九个 -->
          <el-tab-pane class="tab-pane" label="语音控制" name="ninth">
            <div class="box-one">
              <div class="ul">
                <div class="line1">
                  <img height="100%" width="100%" src="../assets/images/语音控制-09.jpg" alt="" />
                </div>
                <div class="line2">
                  <div class="t1">语音控制系统</div>
                  <div class="t2">
                    深度对接各大主流语音机器人，让语音控制更随心。只需一句“Hey
                    Siri，我要睡觉了”，就能一键开启睡眠模式，灯光自动关闭，窗帘缓缓拉上，空调自动调节到深夜模式，从此安心入睡，一夜好梦。
                  </div>
                  <div class="t-box">
                    <div class="t3">
                      <div class="t5">&bull;&nbsp;动口不动手</div>
                      <div class="t5">&bull;&nbsp;全面兼容</div>
                    </div>
                    <div class="t4">
                      <div class="t5">&bull;&nbsp;精准识别</div>
                      <div class="t5">&bull;&nbsp;快速响应</div>
                    </div>
                  </div>
                  <div class="button-one">
                    <el-button round type="primary"  @click="modalShow = true">立即申请&nbsp;&gt;</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- 第十个 -->
          <el-tab-pane class="tab-pane" label="中控管理" name="tenth">
            <div class="box-one">
              <div class="ul">
                <div class="line1">
                  <img height="100%" width="100%" src="../assets/images/solution_picture@2x.png" alt="" />
                </div>
                <div class="line2">
                  <div class="t1">中控管理系统</div>
                  <div class="t2">
                    智能管家帮您集中管理家中所有设备，让各类家电包括照明设备、多媒体设备、移动通信互通互联。您可通过手机App远程控制、查看数据，不在家也能看见家中的一切。无论走到哪里，家都在您手心里。
                  </div>
                  <div class="t-box">
                    <div class="t3">
                      <div class="t5">&bull;&nbsp;稳定可靠</div>
                      <div class="t5">&bull;&nbsp;本地控制</div>
                    </div>
                    <div class="t4">
                      <div class="t5">&bull;&nbsp;断网可控</div>
                      <div class="t5">&bull;&nbsp;强大的数据中转中心</div>
                    </div>
                  </div>
                  <div class="button-one">
                    <el-button round type="primary" @click="modalShow = true">立即申请&nbsp;&gt;</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>

        <div class="box-two">
          <div class="ul">
            <div class="line2">
              <div class="t1">系统架构图</div>
              <div class="t2">
                灵鹿智能提供包括软件、硬件和服务在内的完整解决方案， 主要包括智能灯光、环境传感、协议转换模块、节能环保等全场景的产品系列，
                我们提供App软件且拥有行业内丰富的AI语音生态。基于我们强大的云端架构，我们面向全球的用户提供多元化服务。
              </div>
            </div>
            <div class="line1">
              <img :src="solutionPic" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirmModal :dialogVisible="modalShow" ></confirmModal>
  </div>
</template>

<script>
import imgsUrl from "../utils/imgAddress";
import confirmModal from '@/components/confirm-modal.vue'

export default {
  data() {
    return {
      solutionPic:imgsUrl.solutionMd.solutionPic,
      activeName: "first",
      bannerUrl: imgsUrl.bannerMd.solutionBanner,
      modalShow:false,

    };
  },
  components: {
    confirmModal
  },
  watch:{
    $route(){
      this.scrollMd(this.$route.query.md)
    }
  },
  mounted(){
    this.$nextTick(() => {
      this.$route.query.md && this.scrollMd(this.$route.query.md)
    })
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleModal(){
      this.modalShow = false;
    },
    scrollMd(val){
        let toElement = document.getElementById('solution');
        toElement.scrollIntoView();
        this.activeName =val;
    }
  }
};
</script>
<style scoped lang="scss">
.programme {
  .banner {
    background-size: cover;
    background-position: top;
    background-position-x: center;
    background-position-y: top;
    width: 100%;
    height: 900px;

    position: relative;

    .banner-box {
      position: absolute;
      top: 30%;
      left: 10%;
      color: #d8d8d8;

      .banner-title {
        width: 220px;
        font-size: 48px;
        letter-spacing: 5px;
        padding-bottom: 9px;
        border-bottom: 3px solid #d8d8d8;
        margin-bottom: 29px;
      }

      .title-eng {
        width: 343px;
        font-size: 48px;
        letter-spacing: 2px;
        padding-bottom: 9px;
        border-bottom: 3px solid #d8d8d8;
        margin-bottom: 29px;
        white-space: nowrap;
      }

      .banner-msg {
        letter-spacing: 2px;
        color: #999999;
        font-size: 22px;
      }
    }
  }

  .main {
    color: #fff;
    margin-top: 100px;
    margin-bottom: auto;
    display: flex;
    justify-content: center;

    .content {
      width: 1400px;

      .hand {
        width: 100%;
        text-align: center;
        letter-spacing: 4px;

        .title-msg {
          height: 50px;
          display: block;
          font-size: 32px;
          color: #fff;
          margin-bottom: 14px;
        }

        .p-msg {
          margin: 20px 0 80px 0;
          font-size: 18px;
          color: rgba(128, 128, 128, 1);
          line-height: 160%;
        }
      }

      ::v-deep .el-tabs__nav-scroll {
        width: 90%;
        margin: auto;
      }

      ::v-deep .el-tabs__item {
        //列表默认字
        font-size: 16px !important;
        letter-spacing: 2px;
        line-height: 30px;
        color: rgb(140, 140, 140, 1);
      }

      ::v-deep .el-tabs__item:hover {
        //候选字
        color: rgb(220, 220, 220);
      }

      ::v-deep .el-tabs__item.is-active {
        //已选字
        color: rgb(220, 220, 220);
      }

      ::v-deep .el-tabs__active-bar {
        //已选项
        background-color: rgba(249, 240, 240, 0.774);
      }

      ::v-deep .el-tabs__nav-wrap::after {
        //导航条底部条
        background-color: rgba(57, 57, 57, 1);
      }

      .tab1 {
        .tab-pane {
          color: #fff;
        }

        .box-one {
          margin: 5% 0 0 0;
          height: 520px;

          .ul {
            display: flex;
            justify-content: center;
            height: 520px;
            margin-top: 0;

            .line1 {
              width: 55%;
              height: 100%;
            }

            .line2 {
              width: 45%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              letter-spacing: 2px;

              .t1 {
                margin: 0 0 5% 12%;
                font-size: 30px;
              }

              .t2 {
                height: 40%;
                margin: 0 0 0 12%;
                font-size: 17px;
                color: #a8a9a9;
                line-height: 29px;
              }

              .t-box {
                display: flex;
                font-size: 18px;

                .t3 {
                  width: 60%;
                  margin: 0 0 0 12%;
                  display: flex;
                  flex-direction: column;
                  // background-color: rgba(0, 255, 38, 0.6);
                }

                .t4 {
                  width: 60%;
                  display: flex;
                  flex-direction: column;
                  // background-color: rgba(255, 0, 0, 0.6);
                }

                .t5 {
                  height: 25px;
                  margin: 18px 0;
                }
              }

              .button-one {
                margin: 3% 0 3% 12%;
              }
            }
          }
        }
      }
    }

    .box-two {
      margin: 6% 0 0 0;
      height: 500px;
      background-color: rgb(49, 49, 49, 0.6);
      display: flex;
      justify-content: center;

      .ul {
        width: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;

        .line1 {
          width: 60%;
          height: 100%;
          display: flex;
          align-items: center;
        }

        .line1 img {
          height: 70%;
        }

        .line2 {
          width: 40%;
          letter-spacing: 3px;

          .t1 {
            margin: 0 0 0 20%;
            font-size: 30px;
          }

          .t2 {
            margin: 10% 11% 0 20%;
            font-size: 18px;
            color: #a8a9a9;
            line-height: 30px;
            text-align: justify;

          }
        }
      }
    }
  }
}
</style>
